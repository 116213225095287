$red : #FF5964;
$blue : #4da6ff;
$grey : #B9B7A7;
$black : lighten(black,20%);
$ratio : 60%;
.card
{
  padding: 20px;
  margin: 5px 0;
  border: solid 1px transparent;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  &.clickable:hover
  {
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }
}
.board
{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 50px;
  grid-gap: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: $ratio;
  margin-top: 50px;
}
.main
{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  color: $black;
}
.blue
{
  background-color: darken($blue, 5%);
  color:white;
}
.red
{
  background-color: darken($red, 5%);
  color:white;
}
.black
{
  background-color: $black;
  color:white;
}
.grey
{
  background-color: $grey;
  color:white;
}

.spy
{
  &-blue
  {
    border-color: $blue;
    color: $blue;
  }
  &-red
  {
    border-color: $red;
    color: $red;
  }
  &-black
  {
    border-color: $black;
    color: $black;
  }
  &-grey
  {
    border-color: $grey;
    color: $grey;
  }
}

.teams
{
  width: $ratio;
  display: flex;
  justify-content: space-around;
  &-red,&-blue,&-noteam
  {
    display: flex;
    flex-direction: column;
    padding: 10px 50px;
    border-radius: 50px;
    ul
    {
      padding: 0;
    }
    &.sized
    {
      min-width: 250px;
      min-height: 300px;
    }
  }
  &-noteam
  {
    padding: 10px 30px;
  }
  &-red,&-blue
  {
    color: white;
  }
  &-red
  {
    background-color: $red;
  }
  &-blue
  {
    background-color: $blue;
  }
  &-info
  {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
}

.txt-blue
{
  color: darken($blue,10%);
}
.txt-red
{
  color: darken($red,10%);
}

.tab
{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  position: relative;
  &-info
  {
    display: flex;
    justify-content: space-around;
    align-items: center;
    &__bubble
    {
      border: solid 2px;
      border-radius: 50px;
      padding: 0 50px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      background-color: white;
      &-blue
      {
        &.init
        {      
          color: $blue;
          border-color: $blue;
        }
        &:not(.init)
        {
          -webkit-animation-name: rotateBlue;
          animation-name: rotateBlue;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
      }
      &-red
      {
        &.init
        {      
          color: $red;
          border-color: $red;
        }
        &:not(.init)
        {
          -webkit-animation-name: rotateRed;
          animation-name: rotateRed;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
      }
    }
  }
  &-blue,&-red
  {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    
    padding: 10px 50px;
    min-height: 100px;
    color: white;
  }
  &-blue
  {
    background-color: $blue;
    border-top-left-radius: 50px;
  }
  &-red
  {
    border-top-right-radius: 50px;
    background-color: $red;
  }
}

.main
{
  margin: auto;
  background-color: #fcfcfb;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

h1
{
  font-size: 48px;
}

h2
{
  margin-bottom: 30px;
}
ul
{
  list-style: none;
  li
  {
    font-weight: bold;
    font-size: 18px;
  }
}

.btn
{
  cursor: pointer;
  border: transparent 1px solid;
  border-radius: 50px;
  padding: 10px;
  background-color: darken(white,0%);
  font-size: 18px;
  font-weight: bold;
  &:hover
  {
    background-color: darken(white,5%);
  }
  &-blue
  {
    color: $blue;
  }
  &-team
  {
    margin-top: auto;
    margin-bottom: 30px;
  }
  &-red
  {
    color: $red;
  }
  &-danger
  {
    background-color: darken($red,20%);
    color: white;
    &:hover
    {
      background-color: darken($red,5%);
    }
  }
  &-pass
  {
    border: none;
    margin-left: 20px;
    font-size: unset;
    font-family: unset;
    color: black;
    background-color: darken(white,10%);
    cursor: pointer;
    &:hover
    {
      background-color: darken(white,20%);
    }
  }
  &-noteam
  {
    background-color: darken(white,20%);
    &:hover
    {
      background-color: darken(white,40%);
    }
  }
}
.d-flex
{
  display: flex;
  .btn
  {
    margin: 0 10px;
  }
}

.flash
{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-content
  {
    margin: auto;
    width: 100%;
    h2
    {
      text-align: center;
      margin: 10px;
    }
  }
}

.shadow
{
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.shadow-light
{
  -webkit-box-shadow: 0px 0px 5px 0px rgba(131, 131, 131, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(131, 131, 131, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(131, 131, 131, 0.75);
}

.game
{
  &-header
  {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
.timer
{
  margin-top: 10px;
  p
  {
    font-size: 20px;
  }
}
.name-input
{
  border-radius: 50px;
  background-color: white;
  font-size: 28px;
  text-align: center;
  vertical-align: middle;
}
.legend
{
  margin: 30px 0;
  width: $ratio;
  &>h4
  {
    margin: 5px;
    text-align: center;
  }
}

@-webkit-keyframes rotateBlue {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    color: $red;
    border-color: $red;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 1, 0, 360deg);
    transform: rotate3d(0, 1, 0, 360deg);
    color: $blue;
    border-color: $blue;
  }
}
@keyframes rotateBlue {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    color: $red;
    border-color: $red;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 1, 0, 360deg);
    transform: rotate3d(0, 1, 0, 360deg);
    color: $blue;
    border-color: $blue;
  }
}
@-webkit-keyframes rotateRed {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    color: $blue;
    border-color: $blue;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 1, 0, 360deg);
    transform: rotate3d(0, 1, 0, 360deg);
    color: $red;
    border-color: $red;
  }
}
@keyframes rotateRed {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    color: $blue;
    border-color: $blue;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 1, 0, 360deg);
    transform: rotate3d(0, 1, 0, 360deg);
    color: $red;
    border-color: $red;
  }
}
